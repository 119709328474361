let $archive = $('section.stories-archive');

if($archive.length > 0) {
    let $searchInput = $archive.find('#search-input');
    let $searchButton = $archive.find('.stories-search button');
    let $yearSelect = $archive.find('a#year-select');
    let $categorySelect = $archive.find('a#category-select');
    let $currentPage = 1;
    let container = $archive.find('.container')[0];
    let $showAll = $archive.find('.stories-filter .show-all');

    $yearSelect.click(function(e) {
        e.preventDefault();
        let $parent = $(this).parents('.filter-select');
        if(!$parent.hasClass('open')) {
            $archive.find('.filter-select').removeClass('open');
            $parent.addClass('open');
        }
        else {
            $parent.removeClass('open');
        }    
    });

    $categorySelect.click(function(e) {
        e.preventDefault();
        let $parent =  $(this).parents('.filter-select');
        if(!$parent.hasClass('open')) {
            $archive.find('.filter-select').removeClass('open');
            $parent.addClass('open');
        }
        else {
            $parent.removeClass('open');
        }
    });

    $archive.find('.filter-select ul li a').click(function(e) {
        e.preventDefault();
        $(this).parents('.filter-select').find('ul li a').removeClass('selected');
        $(this).addClass('selected');
        $currentPage = 1;
        getPosts();
        $archive.find('.filter-select').removeClass('open');
    });

    $searchInput.keyup(function (evt) {
        if(evt.key == 'Enter') {
            $currentPage = 1;
            getPosts();
        }
    });

    $searchButton.click(function() {
        $currentPage = 1;
        getPosts();
    });

    $showAll.click(function(e) {
        e.preventDefault();
        $archive.find('.years-select ul li a').removeClass('selected');
        $archive.find('.categories-select ul li a').removeClass('selected');
        $searchInput.val('');
        $archive.find('.filter-select').removeClass('open');
        getPosts();
    });

    function setPagination() {
        $archive.find('.pagination .page-numbers li a').click(function(e) {
            e.preventDefault();
            $currentPage = $(this).data('slug');
            getPosts();
        });
        $archive.find('.pagination li.prev a').click(function(e) {
            e.preventDefault();
            $currentPage -= 1;
            getPosts();
        });
        $archive.find('.pagination li.next a').click(function(e) {
            e.preventDefault();
            $currentPage += 1;
            getPosts();
        });
        if($currentPage > 1) {
            $archive.find('.pagination li.prev').removeClass('disabled');
            $archive.find('.pagination li.prev a').removeClass('disabled');
        }
        if($currentPage == $archive.find('.pagination .page-numbers li').length) {
            $archive.find('.pagination li.next').addClass('disabled');
            $archive.find('.pagination li.next a').addClass('disabled');
        }
        $archive.find('.pagination .page-numbers li').removeClass('current');
        if($archive > 7) {
            $archive.find('.pagination .page-numbers li').eq(6).addClass('current');
        }
        else {
            $archive.find('.pagination .page-numbers li').eq($currentPage - 1).addClass('current');
        }
    }

    $(document).click(function(event) { 
        let $target = $(event.target);
        if(!$target.closest('.filter-select').length && 
        $('.filter-select').hasClass("open")) {
          $('.filter-select').removeClass('open');
        }        
      });

    function getPosts() {
        let $currentYear = $archive.find('.years-select ul li a.selected').data('slug') || 'all';
        let $currentCat = $archive.find('.categories-select ul li a.selected').data('slug') || 'all';
        let $currentSearch = $searchInput.val().toLowerCase();
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('year', $currentYear);
        data.append('category', $currentCat);
        data.append('search', $currentSearch);
        data.append('page', $currentPage);
        $.ajax({
            type: 'POST',
            url: kabal_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
                setPagination();     
            }
        });
    }
    setPagination();
}