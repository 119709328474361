var title = $("article .article-content h1");
var preview = $('article .article-content .preview');
var $svg = $('article svg text');

function splitText(el, pos) {
    if (el.length > 0) {
        el.html('<span>' + el.html().replace(/ /g, '</span> <span>') + '</span>');
        var words = Array.prototype.slice.call(el.find("span"), 0);
        var newText = '';
        var offset = $(words[0]).height() + $(words[0]).offset().top;

        for (var i = 0, c = words.length; i < c; i++) {
            var w = $(words[i]);
            var wbot = w.height() + w.offset().top;
            if (wbot > offset) {
                if (newText == '') {
                    newText = '<br />' + words[i].innerHTML + ' ';
                    offset = wbot;
                }
                else {
                    newText += '<br />' + words[i].innerHTML + ' ';
                    offset = wbot;
                }
            }
            else {
                if (newText == '') {
                    newText = words[i].innerHTML + ' ';
                    offset = wbot;
                }
                else {
                    newText += words[i].innerHTML + ' ';
                    offset = wbot;
                }
            }
        }
        el.html(newText);
    }
    var titleArray = newText.split('<br />');
    for (var i = 0; i < titleArray.length; i++) {
        if (i == 0) {
            if (pos === 'top') {
                $svg.append('<tspan x="0">' + titleArray[i] + '</tspan>');
            }
            else if (pos === 'bottom') {
                if(($("article .article-content .desktop-icon tspan:not('preview-row')").length / 2 > 2) && titleArray.length > 3) {
                    $("article .article-content .desktop-icon").attr("viewBox", "0 0 400 600");
                    $("article .article-content .desktop-icon").find('.blueCircle').attr("cy", "calc(94% - 140px)");
                    $svg.append('<tspan x="80%" dy="2.75em" font-size="40px" class="preview-row">' + titleArray[i] + '</tspan>');
                }
                else if(($("article .article-content .desktop-icon tspan:not('preview-row')").length / 2 > 1) && titleArray.length > 3) {
                    $("article .article-content .desktop-icon").attr("viewBox", "0 0 400 550");
                    $("article .article-content .desktop-icon").find('.blueCircle').attr("cy", "calc(70% - 140px)");
                    $svg.append('<tspan x="80%" dy="4.75em" font-size="40px" class="preview-row">' + titleArray[i] + '</tspan>');
                }
                else {
                    $svg.append('<tspan x="80%" dy="3.75em" font-size="40px" class="preview-row">' + titleArray[i] + '</tspan>');
                }
            }
        }
        else {
            if (pos === 'top') {
                $svg.append('<tspan x="0" dy="1.15em">' + titleArray[i] + '</tspan>');
            }
            else if (pos === 'bottom') {
                $svg.append('<tspan x="80%" dy="1em" font-size="40px" class="preview-row">' + titleArray[i] + '</tspan>');
            }
        }
    }
}
if (title.length > 0) {
    if(title.parents('article').hasClass('post-type-post')) {
        setTimeout(function() {
            splitText(title, 'top');

            if($(window).width() < 768) {
                if(title.height() < 81) {
                    $('.svg-wrapper #circle-title-mobile').attr('viewBox', '0 0 225 200');
                    $('.svg-wrapper').css({ 'width': '225px' });
                }
            }
            else if($(window).width() >= 1024) {
                if(title.height() < 182) {
                    $('.svg-wrapper #circle-title-desktop').attr('viewBox', '0 0 432 300');
                    $('.svg-wrapper').css({ 'width': '432px' });
                }
            }
        }, 100);
    }
}
if (preview.length > 0 && $(window).width() >= 1024) {
    var strippedPreview = preview[0].innerText.slice(0, -1);
    preview.html(strippedPreview);
    setTimeout(function() {
        splitText(preview, 'bottom');
    }, 100);
}

setTimeout(function() {
    $("article .svg-wrapper").html($("article .svg-wrapper").html());
}, 100);