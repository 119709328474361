const $videoBlock = $('section.demo-video');

if($videoBlock.length > 0) {
    $videoBlock.find('.video-wrapper .play-video').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        var $video = $this.siblings('video');
    
        $video.attr('controls', 'controls');
        $video[0].play();
        
        $this.addClass('fade');
        $this.siblings('.overlay').addClass('hide');
        setTimeout(function() {
            $this.siblings('.overlay').remove();
            $this.remove();
        }, 300);
    });
}