var $titles = $('.solutions-preview h1');
$titles.each(function (index) {
    var $text = $(this).text();
    var $firstWord = $text.split(' ')[0];
    $text = $text.replace($firstWord, "<strong>" + $firstWord + "</strong>");
    $(this).html($text);
});

var $solutionSections = $('.solutions-preview .solution-section');
var $height = 0;
setTimeout(function () {
    $solutionSections.each(function () {
        var $solution = $(this).find('.solutions-wrapper').children('a').children('.solution');
        $solution.each(function (index) {
            if ($height < $solution.eq(index).outerHeight()) {
                $height = $solution.eq(index).outerHeight();
            }
        });
        $solution.css({
            'height': $height
        });
    });
}, 200);

var $solutions = $('.solutions-preview .solution');

$solutions.each(function() {
    $(this).hover(function() {
        $(this).addClass('hover');
    }, function() {
        $(this).removeClass('hover');
    });
});