var $menuIcon = $('header .menu-wrapper .menu-icon');
var $header = $('header');
var $body = $('body');
var $heroBg = $('.hero.has-bg');
var $childrenMenuLink = $('header .menu-wrapper .nav-wrapper .menu-links ul li.menu-item-has-children .menu-item-wrapper a');
var $submenuArrow = $('header .menu-wrapper .nav-wrapper .menu-links ul li.menu-item-has-children .submenu-arrow');
var $submenuLink = $('header .menu-wrapper .nav-wrapper .menu-links ul li.menu-item-has-children ul.nav-drop li a');

if ($menuIcon) {
    $menuIcon.click(function () {
        if ($menuIcon.hasClass('menu-open')) {
            $menuIcon.removeClass('menu-open');
            $header.removeClass('show-menu');
            $body.removeClass('no-scroll-y');
        }
        else {
            $menuIcon.addClass('menu-open');
            $header.addClass('show-menu');
            $body.addClass('no-scroll-y');
        }
    });
}

$(window).resize(function () {
    if ($(window).width() >= 900) {
        $('header').removeClass('show-menu');
    }
});

if ($(window).width() < 1250) {
    $childrenMenuLink.click(function (e) {
        e.preventDefault();
        if ($(this).parent().parent().hasClass('submenu-open')) {
            window.location.href = $(this).attr('href');
        }
        else {
            $(this).parent().parent().addClass('submenu-open');
        }
    });

    $submenuArrow.click(function (e) {
        if ($(this).parent().parent().hasClass('submenu-open')) {
            $(this).parent().parent().removeClass('submenu-open');
        }
        else {
            $(this).parent().parent().addClass('submenu-open');
        }
    });

    $submenuLink.click(function(e) {
        $(this).parent().parent().parent().removeClass('submenu-open');
        $menuIcon.removeClass('menu-open');
        $header.removeClass('show-menu');
        $body.removeClass('no-scroll-y');
    });
}

$childrenMenuLink.each(function(index) {
    var $parentLink = $(this).attr('href');
    var $childrenLinks = $(this).parent().parent().children('.nav-drop').find('a');
    $childrenLinks.each(function(i) {
        const link = $(this).attr('href');
        if (link.substring(0, 1) === '#') {
            $(this).attr('href', $parentLink + $(this).attr('href'));
        }
    });
})
