var $header = $('header');
var headerHeight = $header.height();
var $fullHeaderHeight = $header.outerHeight();
var lastScrollTop = 0;
var $adminBar = $('body.admin-bar');

$(window).scroll(function(e) {
    var scrollTop = $(this).scrollTop();
    if($(window).width() < 600 && $adminBar.length > 0 && scrollTop < ((1.5*16) + 46)) {
        $header.removeClass('sticky show animate');
    }
    else if($(window).width() < 1024 && $adminBar.length > 0 && scrollTop < (1.5*16)) {
        $header.removeClass('sticky show animate');
    }
    else if ($(window).width() < 1024 && scrollTop < (1.5*16)) {
        $header.removeClass('sticky show animate');
    } 
    else if (scrollTop > $fullHeaderHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    else if ($(window).width() >= 1024 && $(window).width() < 1250 && scrollTop < (1.5*16)) {
        $header.removeClass('sticky show animate');
    }
    else if ($(window).width() >= 1250 && scrollTop < (3*16)) {
        $header.removeClass('sticky show animate');
    }

    lastScrollTop = scrollTop;
});