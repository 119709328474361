var $infoboxes = $('.infoboxes');
var $childrenBoxes = $infoboxes.find('.box');

var $height = 0;
$(function () {
    if ($(window).width() < 1024) {
        $childrenBoxes.each(function () {
            if ($height < $(this).outerHeight()) {
                $height = $(this).outerHeight();
            }
        });
        $childrenBoxes.css({
            'height': $height
        });
    }
    else {
        $childrenBoxes.css({
            'height': 'initial'
        });
    }
});