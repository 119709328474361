const $featuresBlock = $('section.software-features');

$featuresBlock.find('.continue-indicator a').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.continue-indicator');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top - 50
        }, 400);
    }
});

if($featuresBlock.length > 0) {
    const $categories = $featuresBlock.find('.category-filter a');
    const $search = $featuresBlock.find('input');
    let searchText = '';
    $categories.click(function(e) {
        e.preventDefault();
        let $this = $(this);
        $categories.removeClass('active');
        $this.addClass('active');
        filterFeatures(searchText);
    });
    $search.keyup(function (evt) {
        searchText = evt.target.value.toLowerCase();
        filterFeatures(searchText);
    });
    setMaxHeight();

    $(window).resize(function() {
       setMaxHeight();
    });
}

function setMaxHeight() {
    $featuresBlock.find('.wrapper').each(function() {
        $(this).css({ 'max-height': '100%' });
        let $height = $(this).height();
        if($(window).width() >= 1200) {
            let $maxHeight = $height / 2.095;
            $(this).css({ 'max-height': $maxHeight });
        }
        else if($(window).width() >= 1024) {
            let $maxHeight = $height / 2;
            $(this).css({ 'max-height': $maxHeight });
        }
        else if($(window).width() >= 768) {
            let $maxHeight = $height / 1.4;
            $(this).css({ 'max-height': $maxHeight });
        }
        else {
            $(this).css({ 'max-height': '100%' });
        }
    });
}

function filterFeatures(searchText) {
    let $selectedCategory = $featuresBlock.find('.category-filter a.active').data('slug');
    let $categoryNames, $lists, $listTitles, $listFeatures;

    $featuresBlock.find('h2.category').removeClass('show');
    $featuresBlock.find('.wrapper').removeClass('show');
    $featuresBlock.find('.solutions-list').removeClass('show');          
    $featuresBlock.find('.wrapper .solutions-list ul li').removeClass('show');
    $featuresBlock.find('.wrapper .solutions-list a.list-title').removeClass('show');

    if($selectedCategory == 'all') {
        $categoryNames = $featuresBlock.find('h2.category');
        $lists = $featuresBlock.find('.wrapper .solutions-list');
        $listTitles = $featuresBlock.find('.wrapper .solutions-list a.list-title');
        $listFeatures = $featuresBlock.find('.wrapper .solutions-list ul li');
    }
    else {
        $categoryNames = $featuresBlock.find('h2.category.' + $selectedCategory + '');
        $lists = $featuresBlock.find('h2.category.' + $selectedCategory + '').next().find('.solutions-list');
        $listTitles = $featuresBlock.find('h2.category.' + $selectedCategory + '').next().find('.solutions-list a.list-title');
        $listFeatures = $featuresBlock.find('h2.category.' + $selectedCategory + '').next().find('.solutions-list ul li');
    }
    
    if (searchText != '') {
        $categoryNames.each(function() {
            if ($(this).text().toLowerCase().indexOf(searchText) !== -1) {
                $(this).addClass('show');  
                $(this).next().addClass('show');          
            }
        });
        $lists.each(function() {
            if ($(this).text().toLowerCase().indexOf(searchText) !== -1) {
                $(this).addClass('show');
            }
        });
        $listTitles.each(function() {
            if ($(this).text().toLowerCase().indexOf(searchText) !== -1) {
                $(this).addClass('show');
                $(this).parents('.solutions-list').addClass('show');   
                $(this).parents('.wrapper').addClass('show');
                $(this).parents('.wrapper').prev().addClass('show');       
            }
        });
        $listFeatures.each(function() {
            if ($(this).text().toLowerCase().indexOf(searchText) !== -1) {
                $(this).parents('.wrapper').addClass('show');
                $(this).parents('.wrapper').prev().addClass('show');
                $(this).addClass('show');
                $(this).parents('.solutions-list').addClass('show');
                $(this).parents('.solutions-list').find('a.list-title').addClass('show');
            }
        }); 
    }
    else {
        if($selectedCategory == 'all') {
            $featuresBlock.find('h2.category').addClass('show');
            $featuresBlock.find('h2.category').next().addClass('show');
            $featuresBlock.find('h2.category').next().find('.solutions-list').addClass('show');
            $featuresBlock.find('h2.category').next().find('.solutions-list ul li').addClass('show');
            $featuresBlock.find('h2.category').next().find('.solutions-list a.list-title').addClass('show');
        }
        else {
            $featuresBlock.find('h2.category').each(function() {
                if($(this).hasClass($selectedCategory)) {
                    $(this).addClass('show');
                    $(this).next().addClass('show');
                    $(this).next().find('.solutions-list').addClass('show');
                    $(this).next().find('.solutions-list ul li').addClass('show');
                    $(this).next().find('.solutions-list a.list-title').addClass('show');
                }
            });
        }
    }
    $featuresBlock.find('.continue-indicator').each(function() {
        if($(this).prev().hasClass('show') && $(this).next().hasClass('show')) {
            $(this).css({ 'display' : 'flex' });
        }
        else {
            $(this).css({ 'display' : 'none' });
        }
    });
}