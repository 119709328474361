let $contactBlock = $('section.contact');

if($contactBlock.length > 0 && $(window).width() >= 1440) {
    let $wrapperOffset = $contactBlock.find('.contact-wrapper').offset().left;
    $contactBlock.find('svg').css({
        'transform': 'translateX(' + ($wrapperOffset + 1) + 'px)'
    });
    $(window).resize(function() {
        let $wrapperOffset = $contactBlock.find('.contact-wrapper').offset().left;
        $contactBlock.find('svg').css({
            'transform': 'translateX(' + ($wrapperOffset + 1) + 'px)'
        });
    });
}