var $factBoxes = $('.fact-boxes');
var $childrenBoxes = $factBoxes.find('.box');

var $height = 0;
if ($(window).width() >= 768) {
/*     setTimeout(function () {
        $childrenBoxes.each(function () {
            if ($height < $(this).outerHeight()) {
                $height = $(this).outerHeight();
            }
        });
        $childrenBoxes.css({
            'height': $height
        });
    }, 100); */

    var $boxes = $('.fact-boxes .box a');

    $boxes.each(function () {
        $(this).hover(function () {
            $(this).parent().addClass('hover');
        }, function () {
            $(this).parent().removeClass('hover');
        });
    });
}

var $element = $('.fact-boxes.columns-three .boxes-wrapper');
var run = true;
if ($(window).width() < 768 && $element.length > 0) {
    var width = $element.get(0).scrollWidth,
        containerwidth = $(window).width(),
        left = 0;
    function tick() {
        if (run == true) {
            if (--left < -width) {
                left = containerwidth;
            }
            $element.css("margin-left", left + "px");
            setTimeout(tick, 16);
        }
    }
    tick();
    $element.on('touchstart', function () {
        run = false;
        $(this).css('margin-left', 0);
    });
}