import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bodymovin from 'lottie-web';

var animations = document.querySelectorAll('.animation');
var animationCircle = document.getElementById('circle-anim');
var animationsArray = [];

function setup() {
    for (var i = 0; i < animations.length; i++) {
        loadAnimation(animations[i], i);
    }
}
setup();
setTimeout(setup, 1000);
setTimeout(setup, 3000);

function loadAnimation(element, index) {
    if (element.classList.contains('loaded')) {
        return;
    }
    var data = element.querySelector('.data code');
    animationsArray[index] = bodymovin.loadAnimation({
        container: element, // Required
        animationData: JSON.parse(data.innerHTML),
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        name: element.getAttribute('data-anim'), // Name for future reference. Optional.
    });
    element.classList.add('loaded');
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);


var $textImageSections = $('.home .text-and-image');
$textImageSections.each(function (index) {
    $(this).addClass('text-image-' + (index + 1));
});

setTimeout(function () {
    var $animations = $('.home #anim-svg');
    $animations.each(function (index) {
        $(this).find('svg').parent('.animation').attr('data-anim', (index + 1));
    });
}, 500);

if ($('body').hasClass('home')) {
    ScrollTrigger.batch(".ease-in", {
        interval: 0.1, // time window (in seconds) for batching to occur.
        batchMax: 3,   // maximum batch size (targets)
        onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.15, overwrite: true }),
        start: "top+=50px bottom"
    });

    function playMobile(element) {
        var triggerElement = element.trigger;
        var $blockAnimation = $(triggerElement.querySelector('.animation.mobile'));
        var dataAnim = $blockAnimation.attr('data-anim');
        var bodymovin_data = animationsArray[dataAnim - 1];
        if (bodymovin_data) {
            bodymovin_data.setDirection(1);
            bodymovin_data.play();
        }
    }

    function playAnimation(element) {
        var triggerElement = element.trigger;
        var $blockAnimation = $(triggerElement.querySelector('.animation.desktop'));
        var dataAnim = $blockAnimation.attr('data-anim');
        var bodymovin_data = animationsArray[dataAnim - 1];
        if (bodymovin_data) {
            bodymovin_data.goToAndStop(0, true);
            bodymovin_data.setDirection(1);
            bodymovin_data.play();
        }
    }

    function showCircle() {
        animationCircle.classList.add('show');
        let offsetLeft = document.querySelector('.key-figures .wrapper').offsetLeft;
        animationCircle.style.left = offsetLeft - 350 + 'px';
    }

    function hideCircle() {
        animationCircle.classList.remove('show');
    }

    if ($('body').hasClass('home') && $(window).width() < 1024) {
        if ($(window).width() < 768) {
            var circleElemClip = document.getElementById('circle-mobile-clip');
            var circleElem = document.getElementById('circle-mobile');
        }
        else {
            var circleElemClip = document.getElementById('circle-tablet-clip');
            var circleElem = document.getElementById('circle-tablet');
        }
        var circleLeft = circleElem.getBoundingClientRect().left;
        var heroCircleTopInitial = circleElemClip.getBoundingClientRect().top;

        if($('.clients').length > 0) {
            gsap.to(".clients", {
                scrollTrigger: {
                    trigger: ".clients",
                    start: "top-=100px center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playMobile,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-1').length > 0) {
            gsap.to('.text-image-1', {
                scrollTrigger: {
                    trigger: '.text-image-1 .content-right',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playMobile,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-2').length > 0) {
            gsap.to('.text-image-2', {
                scrollTrigger: {
                    trigger: '.text-image-2 .content-right',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playMobile,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-3').length > 0) {
            gsap.to('.text-image-3', {
                scrollTrigger: {
                    trigger: '.text-image-3 .content-right',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playMobile,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-4').length > 0) {
            gsap.to('.text-image-4', {
                scrollTrigger: {
                    trigger: '.text-image-4 .content-right',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playMobile,
                    once: true,
                },
                immediateRender: false,
            });
        }
    } else {
        var $svg = $('.hero .content-top svg.desktop-icon');
        var $factBoxes = $('.fact-boxes');
        var $keyFigures = $('.key-figures');

        if ($svg.length > 0) {
            var circleElemClip = document.getElementById('circle-desktop-clip');
            var circleElem = document.getElementById('circle-desktop');
            var circleLeft = circleElem.getBoundingClientRect().left;
            var heroCircleTopInitial = circleElemClip.getBoundingClientRect().top;
            var heroCircleWidth = circleElem.getBoundingClientRect().width;
            setTimeout(function () {
                $('.hero .content-top svg.desktop-icon').find('.blueCircle').css('opacity', '1');
            }, 50);
            circleElemClip.style.width = heroCircleWidth;
        }

        var heroCircles = gsap.utils.toArray('#circle-title-desktop .blueCircle');
        heroCircles.forEach((heroCircle) => {
            gsap.to(heroCircle, {
                motionPath: {
                    path: [{ x: (-circleLeft - 310 + ((6.25 * 16))), y: (-147 + 52 - heroCircleTopInitial + ((6.25 * 16))), scale: 0 }, { x: (-310 - circleLeft / 1.25 + ((6.25 * 16))), y: (-147 + 52 - heroCircleTopInitial / 1.5 + ((6.25 * 16))), scale: 1 }, { x: -310, y: -147, scale: 1 }],
                    fromCurrent: false,
                    xPercent: -50,
                    yPercent: -50,
                    transformOrigin: "50% 50%",
                },
                duration: 3,
                ease: "ease",
                immediateRender: false,
            });
        });

        var heroCircles = gsap.utils.toArray('#circle-title-desktop .blueCircle');
        heroCircles.forEach((heroCircle) => {
            gsap.to(heroCircle, {
                motionPath: {
                    path: [{ x: -310, y: -147 }, { x: -310, y: -197 }, { x: -310, y: -147 }],
                    fromCurrent: false,
                    xPercent: -50,
                    yPercent: -50,
                    transformOrigin: "50% 50%"
                },
                delay: 3,
                duration: 2.5,
                repeat: -1,
                ease: "Power0.easeInOut",
            });
        });

        animationCircle.classList.add('ready');
        $(document).scroll(function(e) {
            var $document = $(this);
            var scrollTop = $document.scrollTop();
            var innerHeight = window.innerHeight;
            var scrollCenter = scrollTop + innerHeight / 2;

            if($factBoxes.length > 0) {
                var factBoxesTop = $factBoxes.offset().top;
                var factBoxesHeight = $factBoxes.height();
                var factBoxesBottom = factBoxesTop + factBoxesHeight;
            }
            if($keyFigures.length > 0) {
                var keyFiguresTop = $keyFigures.offset().top;
                var keyFiguresHeight = $keyFigures.height();
                var keyFiguresBottom = keyFiguresTop + keyFiguresHeight;
            }

            if ((scrollCenter >= factBoxesTop && scrollCenter < factBoxesBottom) ||
                (scrollCenter >= keyFiguresTop && scrollCenter < keyFiguresBottom)) {
                showCircle();
            } else {
                hideCircle();
            }
        })

        if($('.clients').length > 0) {
            gsap.to(".clients", {
                scrollTrigger: {
                    trigger: ".clients",
                    start: "top-=100px center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playAnimation,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-1').length > 0) {
            gsap.to('.text-image-1', {
                scrollTrigger: {
                    trigger: '.text-image-1',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playAnimation,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-2').length > 0) {
            gsap.to('.text-image-2', {
                scrollTrigger: {
                    trigger: '.text-image-2',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playAnimation,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-3').length > 0) {
            gsap.to('.text-image-3', {
                scrollTrigger: {
                    trigger: '.text-image-3',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playAnimation,
                    once: true,
                },
                immediateRender: false,
            });
        }

        if($('.text-image-4').length > 0) {
            gsap.to('.text-image-4', {
                scrollTrigger: {
                    trigger: '.text-image-4',
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none reverse none",
                    onEnter: playAnimation,
                    once: true,
                },
                immediateRender: false,
            });
        }

        ScrollTrigger.batch(".savings-facts .fact", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.15, overwrite: true }),
            start: "top bottom",
        });

        ScrollTrigger.batch(".anim-1", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, { x: 0, autoAlpha: 1, stagger: 0.15, overwrite: true }),
            onLeaveBack: batch => gsap.to(batch, { x: "100%", autoAlpha: 0, stagger: 0.15, overwrite: true }),
            start: "center bottom-=200px",
        });
        ScrollTrigger.batch(".anim-2", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, { x: 0, autoAlpha: 1, stagger: 0.15, overwrite: true }),
            onLeaveBack: batch => gsap.to(batch, { x: "100%", autoAlpha: 0, stagger: 0.15, overwrite: true }),
            start: "center bottom-=200px",
        });
        ScrollTrigger.batch(".anim-3", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, { x: 0, autoAlpha: 1, stagger: 0.15, overwrite: true }),
            onLeaveBack: batch => gsap.to(batch, { x: "100%", autoAlpha: 0, stagger: 0.15, overwrite: true }),
            start: "center bottom-=200px",
        });
    }
}
