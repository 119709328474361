var $question = $('.faq .questions .question-wrapper');

if ($question) {
    $($question).click(function() {
        var $answer = $(this).children('.answer-wrapper').children('.answer');
        if ($(this).hasClass('show-answer')) {
            $(this).removeClass('show-answer');
        }
        else {
            $(this).addClass('show-answer');
        }
    });
}