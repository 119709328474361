import Player from '@vimeo/player';
let firstInitialized = false;
let player;

$('.hero .continue-indicator a').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.hero');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top - 50
        }, 400);
    }
});

setTimeout(function () {
    $('.hero .content-top svg').find('.blueCircle').css('display', 'block');
}, 20);

var $svg = $('.hero .content-top svg');
var $homeSVG = $('body.home .hero .content-top svg');

var $textLineCount = $('.hero .content-top #circle-title-desktop text').first().find('tspan').length;
var $svgDesktop = $('.hero .content-top #circle-title-desktop');

if ($homeSVG.parents('.hero').hasClass('has-icon')) {
    $('body.home .hero .content-top #circle-title-mobile text').attr('y', '25%');
    $('body.home .hero .content-top #circle-title-tablet text').attr('y', '30%');
    $('body.home .hero .content-top #circle-title-desktop').attr('viewBox', '0 0 730 530');
    if ($(window).width() >= 1024) {
        $homeSVG.find('circle').attr('r', '147');
    }
    $svg.find('circle').attr('cx', '100%');
    $svg.find('circle').attr('cy', '100%');
}

$('.hero .content-top #circle-title-mobile').find('circle').css({
    'transform': 'translate(-85px, -80px)'
});
$('.hero .content-top #circle-title-tablet').find('circle').css({
    'transform': 'translate(-125px, -125px)'
});

var $overlay = $('.hero .video-overlay');
var $videoPopup = $('.hero .video-popup');
var $videoWrapper = $('.hero .video-popup .video-wrapper');
var $popupTop = $('.hero .video-popup .popup-top')
$('.hero .button-wrapper .link-arrow a').click(function (e) {
    e.preventDefault();
    if ($videoPopup.hasClass('show-popup')) {
        $videoPopup.removeClass('show-popup');
        $overlay.removeClass('show');
        $('body').removeClass('no-scrolling');
        $('.video-wrapper iframe').attr('src', '');
    }
    else {
        $videoPopup.addClass('show-popup');
        $overlay.addClass('show');
        $('body').addClass('no-scrolling');
        var $vimeoUrl = $videoWrapper.data('vimeo-url');
        //var vimeoId = $videoWrapper.data('vimeoId');
        //var h = $videoWrapper.data('h');
        //$videoWrapper.html('<iframe src="https://player.vimeo.com/video/' + vimeoId + '?h=' + h + '" id="vimeo-video" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>')
        //let iframe = $('.hero .video-wrapper');
        if(!firstInitialized) {
            player = new Player($videoWrapper[0]);    
            firstInitialized = true;
        }
        else {
            $('.video-wrapper iframe').attr('src', $vimeoUrl);
            player = new Player($('.video-wrapper iframe')[0]);    
        }
        player.on('play', function() {
            dataLayer.push({'event': 'videoStart'});
        });
    }
});

$(document).click(function (event) {
    var $target = $(event.target);
    if ($target.is($overlay) || $target.is($videoWrapper) || $target.is($popupTop) || $target.is($popupTop.find('img'))) {
        $videoPopup.removeClass('show-popup');
        $overlay.removeClass('show');
        $('body').removeClass('no-scrolling');
        $('.video-wrapper iframe').attr('src', '');
    }
});


var $firstGroup = $svgDesktop.find('#group-desktop');
var $clipGroup = $svgDesktop.find('#clipPathDesktop');
var $groups = $firstGroup.find('g.group-wrapper');
var $clipGroups = $clipGroup.find('g.group-wrapper');
var delays = [];
var widths = [];
var elemDelay = 300;
for (var i = 0; i < $groups.length; i++) {
    var $texts = $groups.eq(i).find('g.group-text');
    var totalWidth = 0;
    var width, bbox;
    delays.push([i]);
    widths.push([i]);
    for (var j = 0; j < $texts.length; j++) {
        bbox = $texts[j].getBBox();
        width = bbox.width;
        var $current = $texts.eq(j);
        delays[i][j] = elemDelay;
        elemDelay = elemDelay + 50;
        $current.css({
            'opacity': 0,
            'transform': 'translate(' + totalWidth + 'px, 100px)'
        });
        var transWidth = totalWidth;
        totalWidth = totalWidth + width + 2;
        widths[i][j] = transWidth;
    }
}

setTimeout(function () {
    for (var i = 0; i < $groups.length; i++) {
        var $texts = $groups.eq(i).find('g.group-text');
        for (var j = 0; j < $texts.length; j++) {
            var $current = $texts.eq(j);
            $current.addClass('animate');
            $current.css({
                'opacity': 1,
                'transition-delay': + (delays[i][j]) + 'ms',
            });
            $current.css({
                'transform': 'translate(' + widths[i][j] + 'px, 0)',
            });
        }
    }
}, 100);

for (var i = 0; i < $clipGroups.length; i++) {
    var $texts = $clipGroups.eq(i).find('g.group-text');
    var totalWidth = 0;
    var width, bbox;
    for (var j = 0; j < $texts.length; j++) {
        bbox = $texts[j].getBBox();
        width = bbox.width;
        var $current = $texts.eq(j);
        $current.css({
            'opacity': 0,
            'transform': 'translate(' + totalWidth + 'px, 100px)'
        });
        var transWidth = totalWidth;
        totalWidth = totalWidth + width + 2;
    }
}

setTimeout(function () {
    for (var i = 0; i < $clipGroups.length; i++) {
        var $texts = $clipGroups.eq(i).find('g.group-text');
        for (var j = 0; j < $texts.length; j++) {
            var $current = $texts.eq(j);
            $current.addClass('animate');
            $current.css({
                'opacity': 1,
                'transition-delay': + (delays[i][j]) + 'ms',
            });
            $current.css({
                'transform': 'translate(' + widths[i][j] + 'px, 0)',
            });
        }
    }
}, 100);

var $easeElems = $('body.home .hero').find('.ease-in');
if ($easeElems.length > 0 && $(window).width() >= 1024) {
    $easeElems.each(function() {
        var $this = $(this);
        if (($easeElems.eq(3).length > 0 && $easeElems.eq(3).offset().top - $easeElems.eq(3).outerHeight()) < $(window).height()) {
            $easeElems.css({ 'opacity': 0, 'transform': 'translateY(100%)' });
            $easeElems.each(function() {
                if($(this).hasClass('continue-indicator')) {
                    $(this).css({ 'opacity': 0, 'transform': 'translate(-50%, 100%)' });
                } 
            });
            $easeElems.removeClass('ease-in');
            setTimeout(function () {
                if($easeElems.eq(3).hasClass('continue-indicator')) {  
                    $easeElems.eq(3).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translate(-50%, 0)' });
                }
                else {
                    $easeElems.eq(3).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translateY(0)' });
                }
            }, 3600);
            setTimeout(function () {
                if($easeElems.eq(2).hasClass('continue-indicator')) {
                    $easeElems.eq(2).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translate(-50%, 0)' });
                }
                else {
                    $easeElems.eq(2).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translateY(0)' });
                }
            }, 3400);
            setTimeout(function () {
                $easeElems.eq(1).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3200);
            setTimeout(function () {
                $easeElems.eq(0).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease', 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3000);
        }
        else if (($easeElems.eq(2).length > 0 && $easeElems.eq(2).offset().top - $easeElems.eq(2).outerHeight()) < $(window).height()) {
            $easeElems.css({ 'opacity': 0, 'transform': 'translateY(100%)' });
            $easeElems.each(function() {
                if($(this).hasClass('continue-indicator')) {
                    $(this).css({ 'opacity': 0, 'transform': 'translate(-50%, 100%)' });
                } 
            });
            $easeElems.removeClass('ease-in');
            $easeElems.eq(3).addClass('ease-in');
            $easeElems.css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease' });
            setTimeout(function () {
                if($easeElems.eq(2).hasClass('continue-indicator')) {
                    $easeElems.eq(2).css({ 'opacity': 1, 'transform': 'translate(-50%, 0)' });
                }
                else {
                    $easeElems.eq(2).css({ 'opacity': 1, 'transform': 'translateY(0)' });
                }
            }, 3400);
            setTimeout(function () {
                if($easeElems.eq(1).hasClass('continue-indicator')) {
                    $easeElems.eq(1).css({ 'opacity': 1, 'transform': 'translate(-50%, 0)' });
                }
                else {
                    $easeElems.eq(1).css({ 'opacity': 1, 'transform': 'translateY(0)' });
                }
            }, 3200);
            setTimeout(function () {
                $easeElems.eq(0).css({ 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3000);
        }
        else if (($easeElems.eq(1).length > 0 && $easeElems.eq(1).offset().top - $easeElems.eq(1).outerHeight()) < $(window).height()) {
            $easeElems.css({ 'opacity': 0, 'transform': 'translateY(100%)' });
            $easeElems.each(function() {
                if($(this).hasClass('continue-indicator')) {
                    $(this).css({ 'opacity': 0, 'transform': 'translate(-50%, 100%)' });
                } 
            });
            $easeElems.removeClass('ease-in');
            $easeElems.eq(2).addClass('ease-in');
            $easeElems.css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease' });
            setTimeout(function () {
                $easeElems.eq(1).css({ 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3200);
            setTimeout(function () {
                $easeElems.eq(0).css({ 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3000);
        }
        else {
            $easeElems.eq(0).css({ 'opacity': 0, 'transform': 'translateY(100%)' });
            $easeElems.eq(0).removeClass('ease-in');
            $easeElems.eq(0).css({ 'transition': 'transform 0.6s ease, opacity 0.6s ease' });
            setTimeout(function () {
                $easeElems.eq(0).css({ 'opacity': 1, 'transform': 'translateY(0)' });
            }, 3000);
        }
    })
}